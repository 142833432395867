








































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class AnswerFree extends Vue {
  public name = "AnswerFree";

  @Prop()
  public answer!: any;

  @Prop()
  public freeQuestion!: any;

  public textSlices: Array<string> = [];
  public inputQuestionFields: Array<any> = [];
  public inputQuestionAnswers: Array<any> = [];

  public answerStyle(correct: boolean) {
    if (correct) {
      return `width: 100px;color: #FFFFFF`;
    }
    return `width: 100px;color: #FFFFFF`;
  }

  public get previewOnlyQuestion(): boolean {
    return !this.answer;
  }

  @Watch("freeQuestion", { immediate: true, deep: true })
  public onFreeQuestionChange(question: any): void {
    if (question && question.questionPattern) {
      const regex = /\{[0-9]+\}/g;
      this.textSlices = question.questionPattern.split(regex);
      const questionFieldsAnswers = question.correctAnswer.split(";");
      const userAnswers = !this.previewOnlyQuestion && this.answer[0].answer ? this.answer[0].answer.split(";") : [""];
      this.inputQuestionFields = userAnswers.map((iField: any, index: number) => {
        if (iField === questionFieldsAnswers[index]) {
          return { index: index, value: iField, correct: true };
        }
        return { index: index, value: iField, correct: false };
      });
      this.inputQuestionAnswers = questionFieldsAnswers;
    }
  }
}
